//@import url('https://fonts.googleapis.com/css2?family=Lexend+Zetta:wght@700&family=Palanquin+Dark:wght@700&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
.App {
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  overflow: auto;
  position: relative;
  font-size: 16px;
  &::-webkit-scrollbar {
    display: none;
  }

  .Page {
    padding: 15px;

    @media screen and (max-width: 767px) {
      margin-bottom: 60px;
    }
  }
}

h1,
h2,
h3 {
  font-weight: 700;
}

h1 {
  font-size: 2.75em;
}

.hidden {
  display: none;
}

.credits {
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.7em;
  font-weight: 800;
  align-items: center;
  background-color: var(--content-bg-color);
  color: var(--content-bg-color);
  border-radius: 50px;
  padding: 1.5px 5px;
  color: rgba(0, 0, 0, 0.85);
  z-index: 5;

  .credits-container {
  }

  .credit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }
  //padding: 5px 10px;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 2.5px;
    object-fit: cover;
  }
}

small {
  font-size: 0.75em;
}

.logo {
  //margin-top: 20px;
  font-family: 'Bungee';
  font-size: 56px;
  position: relative;
  margin-bottom: -10px;
  color: var(--primary-color);
  .circle {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 90px;
    top: 15px;
    border-radius: 50%;
    background-color: var(--primary-color);
  }

  img {
    height: 18px;
    filter: invert(1);
    position: absolute;
    left: 88px;
    top: 18px;
    z-index: 2;
  }
}

.nav-header {
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    height: 80px;
    margin: 0;
  }

  img,
  a {
    margin: 5px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em;
  }
}

.copy-right {
  text-align: center;
  margin: 30px auto;
  font-size: 12px;
  opacity: 0.5;
}

h2 {
  font-size: 30px;
  color: #aca1af;
}

.content {
  .logo {
    margin: auto;
  }
  //padding: 10px 15px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.link {
  cursor: pointer;
}

/***
    The new CSS reset - version 1.8.4 (last updated 14.2.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(
    :not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)
  ) {
  all: unset;
  display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}

/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}

/* remove default dot (•) sign */
::marker {
  content: initial;
}

/* fix the feature of 'hidden' attribute.
 display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}

/* revert for bug in Chromium browsers
 - fix for the content editable attribute will work properly.
 - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable='false'])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable='true']) {
  -webkit-user-drag: element;
}

/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
}

.form-group {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  ::placeholder {
    opacity: 0.5;
  }
  input {
    border: 1px solid #ddd;
    padding: 5px;
  }
}

@media screen and (max-width: 500px) {
  .nav-header {
    justify-content: space-between;
    a {
      padding: 0 5px;
    }
    .logo {
      letter-spacing: 0 !important;
      object-fit: contain !important;
      height: 50px !important;
      width: auto !important;
    }
  }
}
