body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  height: 100%;
  overflow: hidden;
  color: #323E44;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, a {
  cursor: pointer;
}

:root {
  --tabs-bg-color: rgba(0,0,0,.5);
  --content-bg-color: #f9f9f9;
  --faded-text-color: #aca1af;
  --primary-color: #563fd7;
  --border-color: #eaeaea;
  --highlight-color: #e8e5fc;
  --active-color: #24a78d;
}

.faded {
  opacity: .5;
}

.fade-in {
  animation: fadeIn .5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

