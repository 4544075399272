.Books {
  flex-wrap: wrap;
  white-space: wrap;
  max-width: 1200px;
  margin: auto;

  .list {
    display: flex;
    margin: 15px -15px;
    justify-content: center;
    flex-wrap: wrap;

    .item {
      width: 300px;
      padding: 15px;
      img {
        height: 300px;
        width: auto;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}
