.book {
  overflow: auto;
  height: 100vh;
  &::-webkit-scrollbar {
    display: none;
  }

  .summary {
    opacity: 0.6;
    max-width: 800px;
    margin: auto;
    padding: 45px 15px 30px;
    font-size: 1.5em;

    p {
      margin-bottom: 1em;
    }
  }

  header {
    display: flex;
    justify-content: space-between;

    .actions {
      button {
        width: initial;
      }
    }
  }

  .cover-background {
    height: 100%;
    min-height: 200px;
    width: 100%;
    background-size: cover;
  }

  .book-images {
    display: flex;
    overflow-x: auto;
    margin: 30px 0;

    img {
      flex: 0 0 auto;
      margin-right: 10px;
      min-width: 400px;
    }
  }

  .item {
    margin-bottom: 20px;

    h4 {
      font-size: 1.75em;
    }
  }
  header {
    padding: 0 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    div {
      padding: 15px;
      font-weight: 800;
      display: flex;
      align-items: center;
      color: white;
      cursor: pointer;
    }

    .back {
      height: 20px;
      margin-left: -5px;
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;

    button {
      font-weight: 800;
      padding: 7.5px 15px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.15);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .amazon-logo {
        margin-right: 10px;
        height: 20px;
      }

      img {
        height: 20px;
      }
    }
  }
}
