.About {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: auto;

  &.Contact {
    .content {
      a {
        font-weight: 700;
      }
    }
  }

  .authors {
    height: 300px;
    width: auto;
    border-radius: 8px;
    margin-bottom: 25px;
    object-fit: cover;
  }

  .content {
    h1 {
      margin: 1em 0;
    }
    p {
      font-size: 1.5em;
      margin-bottom: 1em;
    }
  }
}
